.container {
    background-color: #fff;
    padding: 50px;
    width: 1550px;
    height: 600px;  
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .logo {
    width: 150px;
    height: auto;
    margin-bottom: 20px;
  }

  
  .button {
    width: 290px;
    padding: 10px;
    color: #fff;
    border: none;
    border-radius: 7px;
    cursor: pointer;
    background-color: #00b49c;
    margin-top: 10px; /* Butonlar arasında boşluk bırak */
    font-size: 17px;
    text-decoration: none; /* Metin alt çizgisini kaldır */
  }
  
  .button a {
    color: #fff; /* Confirm metninin rengini beyaz yap */
    text-decoration: none; /* Confirm metninin alt çizgisini kaldır */
  }
  
  
  .return {
    display: flex;
    justify-content: center; /* İçeriği yatayda merkezle */
    align-items: center; /* İçeriği dikeyde merkezle */
    margin-top: 20px; /* Boşluk eklemek için */
  }
  
  .returnButton {
    display: flex; /* İçeriği yatayda yan yana yerleştir */
    align-items: center; /* İçeriği dikeyde merkezle */
    background: none;
    border: none;
    color: #8d8b8a;
    font-size: 13px;
    cursor: pointer;
    text-decoration: none;
  }
  
  .subtitle {
    color: #8C8A8A;
    font-size: 17px;
    margin-top: 0px;
    margin-bottom: 27px;
    text-align: center; /* Metni yatayda ortala */
  }
  
  
  .returnButtonImage {
    width: 20px; /* İstediğiniz boyuta göre ayarlayın */
    height: auto; /* Otomatik boyutlandırma */
    margin-right: 8px; /* İkon ile metin arasındaki boşluk */
  }
  
  
  
  .title{
    font-size: 25px;
    margin-bottom: 7px;
  }

  .Privacy{
    margin-top: 270px;
    color:#8d8b8a;
    font-size: 12px;
  
  }