.notification {
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    animation: slideIn 0.3s ease forwards, fadeOut 0.3s ease 4s forwards;
  }
  
  .notification p {
    margin: 0;
  }
  
  .success {
    background-color: #4caf50;
    color: #fff;
  }
  
  .error {
    background-color: #f44336;
    color: #fff;
  }
  
  @keyframes slideIn {
    from {
      transform: translateX(-50%) translateY(100%);
    }
    to {
      transform: translateX(-50%) translateY(0);
    }
  }
  
  @keyframes fadeOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
  