.container {
  background-color: #fff;
  padding: 50px;
  width: 1550px;
  height: 600px;  
  display: flex;
  flex-direction: column;
  align-items: center;
}

.lockIcon{
  width: 120px;
  height: auto;
  margin-top: 0;
}


.form {
  max-width: 300px;
  width: 100%;
  text-align: center;
  background-color: #fff; /* Formun arka plan rengini beyaz yap */
}

.label {
  margin-bottom: 0px;
    font-size: 14px;
    text-align: left; /* Email adresi etiketini sola hizala */
    width: 290px; /* Label genişliğini %100 yaparak sola hizalayın */
    display: block; /* Etiketi bir blok element yapın */
    color: #8d8b8a;
}


.input {
  width: 290px;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #8d8b8a;
  border-radius: 7px;
  box-sizing: border-box;
  text-align: left; /* Inputları sola hizala */
}


.button {
  width: 290px;
  padding: 10px;
  color: #fff;
  border: none;
  border-radius: 7px;
  cursor: pointer;
  background-color: #00b49c;
  margin-top: 10px; /* Butonlar arasında boşluk bırak */
  font-size: 17px;
  text-decoration: none; /* Metin alt çizgisini kaldır */
}

.return {
  display: flex;
  justify-content: center; /* İçeriği yatayda merkezle */
  align-items: center; /* İçeriği dikeyde merkezle */
  margin-top: 20px; /* Boşluk eklemek için */
}

.returnButton {
  display: flex; /* İçeriği yatayda yan yana yerleştir */
  align-items: center; /* İçeriği dikeyde merkezle */
  background: none;
  border: none;
  color: #8d8b8a;
  font-size: 13px;
  cursor: pointer;
  text-decoration: none;
}

.subtitle{
  color:#8d8b8a;
  font-size: 17px;
  margin-top: 0px;
  margin-bottom: 50px;
}

.returnButtonImage {
  width: 20px; /* İstediğiniz boyuta göre ayarlayın */
  height: auto; /* Otomatik boyutlandırma */
  margin-right: 8px; /* İkon ile metin arasındaki boşluk */
}



.title{
  font-size: 25px;
  margin-bottom: 7px;
  margin-top: 30px;
}

.Privacy{
  margin-top: 200px;
  color:#8d8b8a;
  font-size: 12px;

}