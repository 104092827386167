

.notFoundContainer {
    background-color: #fff;
    padding: 50px;
    width: 1550px;
    height: 600px;  
    display: flex;
    flex-direction: column;
    align-items: center;
    }
    
    .logo {
      width: 60px;
      height: auto;
      margin-bottom: 20px;
      background-color: #ff000000;
    }
    

    .errorMessage2 {
      color: linear-gradient(to right, #4285F4, #DB4437, #F4B400, #0F9D58);
      text-align: center;
      background-color: #fff;
      font-size: 36px; 
      margin-top: 0;
     
    }
  
  
  
  .signinButton{
    width: 180px;
      padding: 10px;
      color: #fff;
      border: none;
      border-radius: 7px;
      cursor: pointer;
      background-color: #00b49c;
      margin-top: 10px; /* Butonlar arasında boşluk bırak */
      font-size: 17px;
      text-decoration: none; /* Confirm metninin alt çizgisini kaldır */
      margin-right: 10px;
  
  }

  .signupButton{
    width: 180px;
      padding: 10px;
      color: #fff;
      border: none;
      border-radius: 7px;
      cursor: pointer;
      background-color: #00b49c;
      margin-top: 10px; /* Butonlar arasında boşluk bırak */
      font-size: 17px;
      text-decoration: none; /* Confirm metninin alt çizgisini kaldır */
  
  }
  
  
  
  
  .signupButton a {
    color: #fff; /* Confirm metninin rengini beyaz yap */
    text-decoration: none; /* Confirm metninin alt çizgisini kaldır */
  }

  .signinButton a {
    color: #fff; /* Confirm metninin rengini beyaz yap */
    text-decoration: none; /* Confirm metninin alt çizgisini kaldır */
  }