

.notFoundContainer {
  background-color: #fff;
  padding: 50px;
  width: 1550px;
  height: 600px;  
  display: flex;
  flex-direction: column;
  align-items: center;
  }
  
  .logo {
    width: 50px;
    height: auto;
    margin-bottom: 20px;
    background-color: #ff000000;
  }
  
  .errorMessage1 {
    color: #555555; /* veya başka bir renk */
    text-align: center;
    background-color: #fff;
    font-family: 'Roboto', sans-serif;
    font-weight: bold;
    margin-bottom: 10px;
    
  }
  .errorMessage2 {
    color: #00b49c; /* veya başka bir renk */

    text-align: center;
    background-color: #fff;
    font-family: 'Roboto', sans-serif;
    font-size: 56px; 
    margin-top: 0;
  }

  .errorMessage3 {
    color: #555555; /* veya başka bir renk */
    text-align: center;
    background-color: #fff;
    font-family: 'Roboto', sans-serif;
    font-weight: bold;

  }

.notfounButton{
  width: 290px;
    padding: 10px;
    color: #fff;
    border: none;
    border-radius: 7px;
    cursor: pointer;
    background-color: #00b49c;
    margin-top: 10px; /* Butonlar arasında boşluk bırak */
    font-size: 17px;
    text-decoration: none; /* Confirm metninin alt çizgisini kaldır */

}



.notfounButton a {
  color: #fff; /* Confirm metninin rengini beyaz yap */
  text-decoration: none; /* Confirm metninin alt çizgisini kaldır */
}