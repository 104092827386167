/* styles.module.css */

.pageContainer_chat {
  display: flex;
  flex-direction: row;
  width: 100vw;
  height: 100vh;
  background-color: white;
}

.chatPanel_custom {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.leftPanel_custom {
  width: 200px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-right: 20px; /* Boşluk eklemek için */
  border-radius: 10px; /* Border radius ekleme */
}

.leftPanelTop_custom {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.leftPanelBottom_custom {
  display: flex;
  flex-direction: column;
}

.rightPanel_chat {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  padding: 20px;
  border-radius: 10px;
  margin-right: 40px;
}

.logo1_custom {
  width: 60px;
  height: 44px;
  
}
.logo2_custom {
  width: 100px;
  height: 20;
  
}

.chatScreen_custom {
 
  background-color: #f5f5f5;
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 50px;
  overflow-y: auto;
  border-radius: 10px;
  margin-right: 40px;
  height: calc(100% - 140px); /* Değiştirildi */
  width: 1140px;
  margin-top: 25px;
}

.questionInput_custom {
  padding: 20px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 40px;
}

.questionInput_custom input {
  border: 1px solid #8C8A8A;
  border-radius: 12px;
  width: calc(100% - 70px); /* Genişliği butonun genişliğine göre ayarla */
  height: 40px;
  font-size: 24px;
  padding-left: 20px;
  border-color: #8C8A8A;
}

.submitQuestionButton_custom {
  background-color: #ffffff00;
  border: none;
  cursor: pointer;
  margin-left: 0;
}
.submitQuestionButton_custom:hover{
  background-color: #ffffff00;

}

.newChatButton_custom,
.feedbackButton_custom {
  width: 180px;
  height: 40px;
  padding: 10px;
  background-color: #00b49c;
  color: white;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  margin-top: 10px;
  font-size: 18px;
}

.newChatButton_custom:hover,
.feedbackButton_custom:hover {
  background-color: #089781; /* Butonun rengini hover durumunda aynı şekilde tut */
}

.userInfo_custom {
  display: flex;
  align-items: center;
  margin-top: 20px; /* Yukarıdan gelen boşluğu artırır */
}

.userPhoto_custom {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  margin-right: 10px; /* Kullanıcı fotoğrafının sağında bir boşluk bırakır */
}



.label_custom {
  font-weight: bold;
}

.userInfo2_custom {
  display: flex;
  align-items:flex-start;
  justify-content: flex-start; /* Butonu sola hizalar */
  margin-top: 20px; /* Yukarıdan gelen boşluğu artırır */
}


.logoutContainer_custom {
  margin-right: auto; /* Logout butonunu sağa hizalar */
  margin-left: 10px; /* Logout butonunu soldan biraz uzaklaştırır */
}

.logoutButton_custom {
  padding: 10px;
  background-color: #00000000;
  border: none;
  color: #000000;
  font-size: 18px;
  cursor: pointer;
}

.logoutIcon_custom {
  width: 25px; /* İstediğiniz genişliği ayarlayın */
  height: 25px; /* İstediğiniz yüksekliği ayarlayın */
  vertical-align: middle; /* İkonu dikey olarak hizalayın */
  margin-right: 5px; /* İkon ile buton arasına bir boşluk bırakın */
}


.userName_custom{
  font-weight: bold;
  font-size: 18px;
}

/* ChatPage sayfasına giriş animasyonu */
.fade-enter_custom {
  opacity: 0; /* Sayfa girişinde başlangıçta görünmez olacak */
}

.fade-enter-active_custom {
  opacity: 1; /* Geçişin aktif olduğu süre boyunca opaklık artacak */
  transition: opacity 0.5s ease-in-out; /* Geçiş animasyonu ayarları */
}

.logoContainer_custom {
  display: flex; /* Yan yana yerleştirme */
}

.questionInput_custom input::placeholder {
  font-size: 24px; /* Yazı boyutu */
 
  color:#8C8A8A
}

.questionInput_custom input:focus::placeholder {
  color: transparent; /* Inputa tıklandığında placeholder'ı şeffaf yap */
}


.questionInput_custom input:focus {
  outline: none;
  border-color: #8C8A8A; /* veya istediğiniz bir renk */
}
