/* FeedbackModal.module.css */

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(8px);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal {
    background-color: white;
    padding: 40px; /* Padding artırıldı */
    border-radius: 10px;
    width: 420px; /* Genişlik artırıldı */
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 500px; /* Yükseklik artırıldı */
    overflow-y: auto; /* Eğer içerik çok uzunsa scrollbar göster */
    border: 2px solid #b8b7b7;

}

.closeIcon {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    font-size: 24px;
}

.Feedbackform {
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px; /* Formun üst kısmında boşluk bırak */
}

.formGroup {
    margin: 10px 0; /* Form elemanları arasındaki boşluğu azalt */
    width: 100%;
    margin-top: 10px;
}

.allLabels {
    font-size: 14px;
    font-weight:normal;
    margin-bottom: 30px;
}

.fullnameInput,
.emailInput,
.feedbackTextarea {
    width: calc(100% - 20px); /* Genişlik kısaltıldı */
    padding: 8px; /* Inputların padding'i artırıldı */
    margin-bottom: 10px; /* Inputlar arasındaki boşluğu azalt */
    border: 2px solid #b8b7b7;
    border-radius: 7px;
    height: 24px;

}
.fullnameInput:focus,
.emailInput:focus,
.feedbackTextarea:focus {
    border-color: #b8b7b7; /* Tıklandığında border rengini değiştir */
    outline: none; /* Varsayılan outline'u kaldır */
}



.cancelButton {
    margin-left: 10px; /* Cancel butonu ile submit butonu arasına boşluk bırak */
    color:white;
    padding: 10px 20px; /* Butonun padding'i artırıldı */
    border: 2px;
    background-color: #dfdada;
    color: white;
    border-radius: 8px;
     cursor: pointer;
     margin-top: 10px; /* Buton ile form arasındaki boşluk artırıldı */
     width: 130px;
     border: 2px solid #8C8A8A;
    color:#8C8A8A;
     font-size: 20px;
     margin-bottom: 10px;
        
    
}


.submitButton {
    background-color: #00b49c; /* Cancel butonunun rengi kırmızı */
    margin-left: 10px; /* Cancel butonu ile submit butonu arasına boşluk bırak */
    border: 2px solid #00b49c;
    padding: 10px 20px; /* Butonun padding'i artırıldı */
    color: white;
    border-radius: 8px;
     cursor: pointer;
     margin-top: 10px; /* Buton ile form arasındaki boşluk artırıldı */
     width: 130px;
     font-size: 20px;
     margin-bottom: 10px;
        
    
}

.submitButton:hover {
    color:white;
    background-color: #089781;


}

.cancelButton:hover {
    background-color: #7e7c7c;
    color: white;

}
.buttonGroup {
    display: flex;
    justify-content: center; /* Butonları ortala */
    margin-top: 0; /* Butonlar ile form arasındaki boşluğu ayarla */
}

.feedbackTextarea {
    margin-bottom: 0; /* Textarea'nın altındaki boşluğu kaldır */
    height: 75px; /* Textarea'nın sabit yüksekliği */
    overflow-y: auto; /* Yükseklik aşıldığında scrollbar göster */
    resize: none; /* Kullanıcının boyutunu değiştirmesini engelle */
}

.Title{
    font-weight: bolder;
    font-size: 28px;
}